import React, { useEffect, useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/outline";

import "./dropdown.css";

const determineLogoSize = (width) => {
  if (width >= 1024) {
    return 20;
  } else {
    return 15;
  }
};

export const Dropdown = ({ yearSelection }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [selectedOption, setSelectedOption] = useState("2024-25");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (value, label) => {
    setSelectedOption(label);
    yearSelection(label);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button className="dropdown--button" onClick={toggleDropdown}>
        <h6>{selectedOption}</h6>
        <div className="dropdown--divider"></div>
        <ChevronDownIcon style={{ width: determineLogoSize(windowWidth) }} />
      </button>
      {isOpen && (
        <div className="dropdown--content">
          <div
            className="dropdown--option"
            onClick={() => handleSelect("2023-24", "2023-24")}
          >
            <h6>2023-24</h6>
          </div>
          <div
            className="dropdown--option"
            onClick={() => handleSelect("2024-25", "2024-25")}
          >
            <h6>2024-25</h6>
          </div>
        </div>
      )}
    </div>
  );
};
