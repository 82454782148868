import React from "react";
import PropTypes from "prop-types";

/**
 * NAME: Rob Worron
 * DATE: September 29th, 2023
 */

/**
 * This Logo component is widely used across the website including the Header and the NavBar. Using
 * {name}, it retrieves an image from the 'assets' folder and renders it with a given {size}. The user
 * has the option of adding horizontal {padding}.
 */

// The UI for a Logo component
export const Logo = ({ name, size, padding }) => {
  const image = require(`../../assets/logos/${name}.png`);
  const imageStyling = {
    width: `${size}px`,
    paddingLeft: `${padding}px`,
    paddingRight: `${padding}px`,
  };

  return <img src={image} style={imageStyling} alt="" />;
};

// Props for the logo
Logo.propTypes = {
  // What is the name of the asset to be retrieved?
  name: PropTypes.string,

  // What is the size of the Logo?
  size: PropTypes.number,

  // What is the horizontal padding the user wishes to add to the logo?
  padding: PropTypes.number,
};

// Default props for a Logo
Logo.defaultProps = {
  name: "wordmark-transparent",
  size: 50,
  padding: 0,
};
