import React from "react";
import { InfoTable } from "../../components/InfoTable/InfoTable";
import { Rules } from "../../components/Rules/Rules";

import "./infopage.css";

/**
 * NAME: Rob Worron
 * DATE: September 29th, 2023
 */

/**
 * The InfoPage is a page where website visitors can get a view of league information. Like all webpages,
 * it uses a Header, NavBar, SideBar, and Footer to build the core aspects of the page. In addition to
 * these components, the main section of the page utilizes an InfoTable to display league information.
 * It uses the stylings from webpage.css and infopage.css to style its content.
 */

// The UI of the InfoPage
export const InfoPage = () => (
  <div className="infopage">
    <div className=" infopage--body">
      <InfoTable />
      <Rules />
    </div>
  </div>
);
