import React, { useState, useEffect } from "react";
import { Standings } from "../../components/Standings/Standings";
import { Dropdown } from "../../components/Dropdown/Dropdown";
import { StandingsLegend } from "../../components/StandingsLegend/StandingsLegend";
import { Tiebreak } from "../../components/Tiebreak/Tiebreak";
import axios from "axios";

import "./standingspage.css";

export const StandingsPage = () => {
  const [selectedYear, setSelectedYear] = useState("2024-25");
  const [standings, setStandings] = useState([]);
  const [error, setError] = useState(null);

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  useEffect(() => {
    const fetchStandings = async () => {
      const url = `https://cmhl.vercel.app/api/${
        selectedYear === "2023-24" ? "2023_standings" : "2024_standings"
      }`;
      try {
        const response = await axios.get(url);
        setStandings(response.data);
      } catch (e) {
        setError(`ERROR: Failed to fetch ${selectedYear} Standings`);
      }
    };

    fetchStandings();
  }, [selectedYear]);

  return (
    <div className="standingspage">
      <div className="standingspage--body">
        <div className="standingspage--header">
          <h1 className="standingspage--title">Standings</h1>
          <Dropdown yearSelection={handleYearChange} />
        </div>
        {error ? <h2>{error}</h2> : <Standings standingsData={standings} />}
        <StandingsLegend />
        <Tiebreak />
      </div>
    </div>
  );
};

export default StandingsPage;
