import React from "react";
import { SkaterStats } from "../../components/SkaterStats/SkaterStats";
import { GoalieStats } from "../../components/GoalieStats/GoalieStats";
import { StatsLegend } from "../../components/StatsLegend/StatsLegend";

import "./statspage.css";

export const StatsPage = () => (
  <div className="statspage">
    <div className="statspage--body">
      <div className="statspage--header">
        <h1>Stats</h1>
        <h2>Skaters</h2>
      </div>
      <SkaterStats />
      <div className="statspage--header">
        <h2>Goalies</h2>
      </div>
      <GoalieStats />
      <StatsLegend />
    </div>
  </div>
);
