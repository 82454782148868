import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Logo } from "../Logo/Logo";

import "./standings.css";

export const Standings = ({ sidebar, standingsData }) => {
  const getTeamLogoFileName = (teamName) =>
    teamName.replace(/\s/g, "").toLowerCase() + "-transparent";

  const determineLogoSize = (width) => {
    if (width >= 1440) {
      if (sidebar) {
        return 40;
      }
      return 120;
    } else if (width >= 768) {
      if (sidebar) {
        return 30;
      }
      return 70;
    } else {
      return 30;
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const header = ["RK", "Team", "W", "L", "T", "P", "GF", "GA", "GD", "ST"];

  if (!Array.isArray(standingsData) || standingsData.length === 0) {
    return <p>No standings data available.</p>;
  }

  return sidebar ? (
    <table className="sidebar-standings">
      <thead>
        <tr>
          <th className="sidebar-standings--rank-cell">{header[0]}</th>
          <th className="sidebar-standings--team-cell sidebar-standings--header-team-cell">
            {header[1]}
          </th>
          {header.slice(2, 6).map((headerData, index) => (
            <th key={index} className="sidebar-standings--stat-cell">
              {headerData}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {standingsData.map((row, rowIndex) => (
          <tr key={rowIndex}>
            <td className="sidebar-standings--rank-cell">{row[0]}</td>
            <td className="sidebar-standings--team-cell">
              <Logo
                name={getTeamLogoFileName(row[1])}
                size={determineLogoSize(windowWidth)}
                padding={2}
              />
              {row[1]}
            </td>
            {header.slice(2, 6).map((headerData, colIndex) => (
              <td key={colIndex + 2} className="sidebar-standings--stat-cell">
                {colIndex === 3 ? (
                  <b>{row[colIndex + 2]}</b>
                ) : (
                  row[colIndex + 2]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <table className="standings">
      <thead className="standings--header">
        <tr>
          <th className="standings--rank-cell">{header[0]}</th>
          <th className="standings--team-cell">{header[1]}</th>
          {header.slice(2).map((headerData, index) => (
            <th key={index} className="standings--stat-cell">
              {headerData}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {standingsData.map((row, rowIndex) => (
          <tr key={rowIndex}>
            <td className="standings--rank-cell">{row[0]}</td>
            <td className="standings--team-cell">
              <Logo
                name={getTeamLogoFileName(row[1])}
                size={determineLogoSize(windowWidth)}
                padding={2}
              />
              {row[1]}
            </td>
            {header.slice(2).map((headerData, colIndex) => (
              <td key={colIndex + 2} className="standings--stat-cell">
                {colIndex === 3 ? (
                  <b>{row[colIndex + 2]}</b>
                ) : (
                  row[colIndex + 2]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

Standings.propTypes = {
  sidebar: PropTypes.bool.isRequired,
  standingsData: PropTypes.array,
};

Standings.defaultProps = {
  sidebar: false,
};
