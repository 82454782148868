import React, { useState, useEffect } from "react";
import { Logo } from "../Logo/Logo";
import axios from "axios";

import "./skaterstats.css";

export const SkaterStats = () => {
  const teamNameMap = {
    RCK: "rockies",
    AXE: "axemen",
    GUL: "gulls",
    TTU: "toonietuesday",
    JGR: "jagrbombs",
    MDR: "mightydrunks",
  };

  const getTeamLogoFileName = (teamAcronym) => {
    const teamName = teamNameMap[teamAcronym];
    const modifiedTeamName = teamName.replace(/\s/g, "").toLowerCase();
    return modifiedTeamName + "-transparent";
  };

  const determineLogoSize = (width) => {
    if (width >= 1440) {
      return 70;
    } else if (width >= 768) {
      return 55;
    } else {
      return 40;
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [stats, setStats] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(
          "https://cmhl.vercel.app/api/2024_stats_skater"
        );
        setStats(response.data);
      } catch (e) {
        setError("ERROR: Failed to fetch 2024 Skater Statistics");
      }
    };

    fetchStats();
  }, []);

  if (error) return <h2>{error}</h2>;

  const header = [
    "RK",
    "Player",
    "Team",
    "GP",
    "G",
    "A",
    "P",
    "PIM",
    "PPP",
    "SHP",
  ];
  return (
    <table className="skaterstats">
      <thead className="skaterstats--header">
        <tr>
          {header.map((headerData, headerIndex) => (
            <th key={headerIndex}>{headerData}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {stats.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((col, colIndex) =>
              colIndex === 1 ? (
                <td key={colIndex} className="skaterstats--player-cell">
                  <Logo
                    name={getTeamLogoFileName(row[2])}
                    size={determineLogoSize(windowWidth)}
                  />
                  <b>{col}</b>
                </td>
              ) : colIndex === 6 ? (
                <td key={colIndex}>
                  <b>{col}</b>
                </td>
              ) : (
                <td key={colIndex}>{col}</td>
              )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
