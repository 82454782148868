import React, { useState, useEffect } from "react";
import { Logo } from "../Logo/Logo";
import "./schedule.css";

export const Schedule = ({ scheduleData }) => {
  const getTeamLogoFileName = (teamName) => {
    const modifiedTeamName = teamName.replace(/\s/g, "").toLowerCase();
    return modifiedTeamName + "-transparent";
  };

  const determineLogoSize = (width) => {
    if (width >= 1440) {
      return 70;
    } else if (width >= 768) {
      return 50;
    } else {
      return 25;
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getNumType = (num) => {
    return Number(num) % 2 === 0 ? "even" : "odd";
  };

  const header = [
    "WK",
    "GM",
    "Date",
    "Rink",
    "Time",
    "Home",
    "HS",
    "Away",
    "AS",
  ];

  return (
    <table className={"schedule"}>
      <thead className={"schedule--header"}>
        <tr>
          {header.map((headerData, index) => (
            <th key={index}>{headerData}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {scheduleData.map((row, rowIndex) => (
          <tr key={rowIndex} className={`schedule--row-${getNumType(row[0])}`}>
            {row.map((cellData, colIndex) => (
              <td key={colIndex}>
                {header[colIndex] === "Home" || header[colIndex] === "Away" ? (
                  <div className="schedule--team-cell">
                    {cellData !== "--" && (
                      <>
                        <Logo
                          name={getTeamLogoFileName(cellData)}
                          size={determineLogoSize(windowWidth)}
                        />
                        {cellData}
                      </>
                    )}
                    {cellData === "--" && "--"}
                  </div>
                ) : (
                  <div className="schedule--center">{cellData}</div>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
