import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Header } from "./components/Header/Header";
import { NavBar } from "./components/NavBar/NavBar";
import { SideBar } from "./components/SideBar/SideBar";
import { Footer } from "./components/Footer/Footer";

import { ScheduleProvider } from "./contexts/2024_ScheduleContext";
import { StandingsProvider } from "./contexts/2024_StandingsContext";

import { HomePage } from "./pages/HomePage/HomePage";
import { InfoPage } from "./pages/InfoPage/InfoPage";
import { StatsPage } from "./pages/StatsPage/StatsPage";
import { SchedulePage } from "./pages/SchedulePage/SchedulePage";
import { StandingsPage } from "./pages/StandingsPage/StandingsPage";

import "./webpage.css";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <Router>
    <ScheduleProvider>
      <StandingsProvider>
        <div className="webpage">
          <div className="webpage--header">
            <Header />
            <NavBar />
          </div>
          <main>
            <div className="webpage--sidebar">
              <SideBar />
            </div>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/info" element={<InfoPage />} />
              <Route path="/stats" element={<StatsPage />} />
              <Route path="/schedule" element={<SchedulePage />} />
              <Route path="/standings" element={<StandingsPage />} />
            </Routes>
          </main>
          <div className="webpage--footer">
            <Footer />
          </div>
        </div>
      </StandingsProvider>
    </ScheduleProvider>
  </Router>
);
