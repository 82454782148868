import React, { useEffect, useState } from "react";

import "./infotable.css";

/**
 * NAME: Rob Worron
 * DATE: September 29th, 2023
 */

/**
 * This InfoTable component is designed to display a variety of league information including the
 * league title, the inaugural season of the league, the rink name, the location of the rink, the
 * teams in the league, its current year's start and end dates, and a picture of the rink.
 */

// The UI for an InfoTable component

export const InfoTable = () => {
  const image = require("../../assets/gale-centre.jpg");

  return (
    <div className="infotable">
      <h1>CMHL League Information</h1>
      <div className="infotable--information">
        <img src={image} />
        <table className="infotable--table">
          <tbody>
            <tr>
              <td>League</td>
              <td>Canucks Men's Hockey League</td>
            </tr>
            <tr className="infotable--row">
              <td>Inaugural Season</td>
              <td>2023</td>
            </tr>
            <tr className="infotable--row">
              <td>Rink</td>
              <td>Gale Centre Arena</td>
            </tr>
            <tr className="infotable--row">
              <td>Location</td>
              <td>5152 Thorold Stone Road, Niagara Falls, ON.</td>
            </tr>
            <tr className="infotable--row">
              <td>Teams</td>
              <td>
                Axemen, Gulls, Jagrbombs, Mighty Drunks, Rockies, Toonie
                Tuesdays
              </td>
            </tr>
            <tr className="infotable--row">
              <td>League Start Date</td>
              <td>Friday, September 27, 2024</td>
            </tr>
            <tr className="infotable--row">
              <td>League End Date</td>
              <td>Friday, March 21, 2025</td>
            </tr>
            <tr className="infotable--row">
              <td>Contact</td>
              <td>cmhlniagara@gmail.com</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
